define("discourse/plugins/discourse-adplugin/discourse/components/carbonads-ad", ["exports", "@ember/component", "@ember/template", "discourse-common/utils/decorators", "discourse/plugins/discourse-adplugin/discourse/components/ad-component", "ember-this-fallback/this-fallback-helper", "ember-this-fallback/try-lookup-helper", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _component, _template, _decorators, _adComponent, _thisFallbackHelper, _tryLookupHelper, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if showAd}}
    {{! template-lint-disable no-forbidden-elements }}
    <script src={{url}} id="_carbonads_js" async type="text/javascript">
    </script>
  {{/if}}
  */
  {
    "id": "+Uo79OGY",
    "block": "[[[41,[30,0,[\"showAd\"]],[[[1,\"  \"],[44,[[28,[37,2],null,[[\"url\"],[[28,[32,0],[\"url\"],null]]]]],[[[10,\"script\"],[15,\"src\",[52,[30,1,[\"url\"]],[28,[30,1,[\"url\"]],null,null],[28,[32,1],[[30,0],\"url\",\"[\\\"The `url` property path was used in the `discourse/plugins/discourse-adplugin/discourse/components/carbonads-ad.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.url}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]\"],null]]],[14,1,\"_carbonads_js\"],[14,\"async\",\"\"],[14,4,\"text/javascript\"],[12],[1,\"\\n  \"],[13]],[1]]],[1,\"\\n\"]],[]],null],[1,[28,[32,2],[\"[[\\\"The `showAd` property path was used in the `discourse/plugins/discourse-adplugin/discourse/components/carbonads-ad.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.showAd}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[\"maybeHelpers\"],false,[\"if\",\"let\",\"hash\"]]",
    "moduleName": "discourse/plugins/discourse-adplugin/discourse/components/carbonads-ad.hbs",
    "scope": () => [_tryLookupHelper.default, _thisFallbackHelper.default, _deprecationsHelper.default],
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _adComponent.default.extend(dt7948.p({
    serve_id: null,
    placement: null,
    init() {
      this.set("serve_id", this.siteSettings.carbonads_serve_id);
      this.set("placement", this.siteSettings.carbonads_placement);
      this._super();
    },
    url(serveId, placement) {
      return (0, _template.htmlSafe)(`//cdn.carbonads.com/carbon.js?serve=${serveId}&placement=${placement}`);
    },
    showCarbonAds() {
      if (!this.currentUser) {
        return true;
      }
      return this.currentUser.show_carbon_ads;
    },
    showAd(placement, serveId, showCarbonAds, showToGroups, showOnCurrentPage) {
      return placement && serveId && showCarbonAds && showToGroups && showOnCurrentPage;
    }
  }, [["method", "url", [(0, _decorators.default)("serve_id", "placement")]], ["method", "showCarbonAds", [_decorators.default]], ["method", "showAd", [(0, _decorators.default)("placement", "serve_id", "showCarbonAds", "showToGroups", "showOnCurrentPage")]]])));
});