define("discourse/plugins/discourse-adplugin/discourse/helpers/slot-position", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isNthPost = isNthPost;
  _exports.isNthTopicListItem = isNthTopicListItem;
  function isNthPost(every, currentPostNumber) {
    if (every && every > 0) {
      return currentPostNumber % every === 0;
    } else {
      return false;
    }
  }
  function isNthTopicListItem(every, currentIndexPosition) {
    if (every && every > 0 && currentIndexPosition > 0) {
      return (currentIndexPosition + 1) % every === 0;
    } else {
      return false;
    }
  }
});